import React from "react";
// import classNames from "classnames";
import { Text, Image } from "@atoms";
import { AppLink, Icon } from "@base";
import classNames from "classnames";

const Hero = ({ subheading, tagline, image, path }) => {
  return (
    <section className="py-12 sm:py-24 relative min-h-2/3-screen">
      {image && <Image fill name={`${image}.jpg`} />}
      <div className="bg-gradient-to-b from-transparent to-black absolute h-[25%] sm:h-[75%] opacity-50 sm:opacity-100 bottom-0 left-0 right-0" />
      <div className="bg-gradient-to-t from-transparent to-black absolute h-[75%] top-0 left-0 right-0 opacity-25" />
      <div className="sm:hidden flex flex-wrap gap-6 items-center justify-center -mt-6 mb-12 relative z-20">
        <AppLink
          partiallyActive={false}
          className="font-bold flex flex-col items-center justify-start w-32 group"
          to="/business-strategy"
        >
          Business
          <Icon
            name="underline_1"
            className={classNames(
              "-mx-3 transition duration-200 group-hover:translate-y-0 group-hover:opacity-100",
              {
                "opacity-0 translate-y-1/4 transform":
                  path !== "/business-strategy",
              }
            )}
          />
        </AppLink>
        <AppLink
          partiallyActive={false}
          className="font-bold flex flex-col items-center justify-start w-32 group"
          to="/"
        >
          Individual
          <Icon
            name="underline_2"
            className={classNames(
              "-mx-3 transition duration-200 group-hover:translate-y-0 group-hover:opacity-100",
              {
                "opacity-0 translate-y-1/4 transform": path !== "/",
              }
            )}
          />
        </AppLink>
      </div>
      <div className="relative z-20 flex flex-col items-center justify-center">
        <div className="flex items-center justify-center gap-6 lg:gap-12">
          <AppLink
            partiallyActive={false}
            className="font-bold hidden sm:flex flex-col items-center justify-start w-32 group"
            to="/business-strategy"
          >
            Business
            <Icon
              name="underline_1"
              className={classNames(
                "-mx-6 transition duration-200 group-hover:translate-y-0 group-hover:opacity-100",
                {
                  "opacity-0 translate-y-1/4 transform":
                    path !== "/business-strategy",
                }
              )}
            />
          </AppLink>
          <Icon
            name="lisa_benson"
            className="text-white h-20 w-full max-w-full"
            fitHeight
            // encode
            // eslint-disable-next-line react/no-unstable-nested-components
            placeholder={() => (
              <Text variant="h1" className="my-3">
                Lisa Benson
              </Text>
            )}
          />
          <AppLink
            partiallyActive={false}
            className="font-bold hidden sm:flex flex-col items-center justify-start w-32 group"
            to="/"
          >
            Individual
            <Icon
              name="underline_2"
              className={classNames(
                "-mx-6 transition duration-200 group-hover:translate-y-0 group-hover:opacity-100",
                {
                  "opacity-0 translate-y-1/4 transform": path !== "/",
                }
              )}
            />
          </AppLink>
        </div>
        <h2 className="mt-8 tracking-[2px] font-bold text-sm uppercase px-6 text-center leading-normal">
          {subheading}
        </h2>
        <Text variant="h3" className="text-center px-6 italic mt-32">
          {tagline}
        </Text>
      </div>
    </section>
  );
};

export default Hero;
